import {
  Box, Button, Checkbox, Chip, CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Typography
} from "@mui/material";
import {ArrowDropDown, ChevronRight} from "@mui/icons-material";
import React, {ChangeEvent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {makeStyles} from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import {useDispatch, useSelector} from "react-redux";
import {getColumnItems, setProductOpt} from "store/slices/StockProductSlice";
import {useProductContext} from "providers/product/ProductProvider";

const useStyles = makeStyles(() => ({
  Txt: {
    color: 'black'
  },
  ListItemText: {
    fontSize: 14,
    textTransform: 'capitalize'
  },
  ListItemButton: {
    padding: '5px 11px'
  },
  Input: {
    flex: 1,
    color: '#849095',
    padding: '0.4rem 1rem',
    fontFamily: 'Poppins',
    // textTransform: 'uppercase',
    // cursor: 'pointer',
    background: 'transparent',
    width: 'calc(100% - 20px)',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid #d8dde6',
    margin: '10px',
    fontSize: '13px',
    lineHeight: '1.25rem',
    '&:focus': {
      outline: 'none',
    },
  },
}));

interface FilterSelectProps {
  placeholder?: string
  label?: string;
  column?: any
  checkedOptions?: any[]
  onChange: (value: string) => void;
}

export const FilterSelect: React.FC<FilterSelectProps> = ({
                                                            label,
                                                            placeholder,
                                                            column = [],
                                                            checkedOptions = [],
                                                            onChange,
                                                            ...rest
                                                          }) => {
  const classes = useStyles()
  const {} = useProductContext()
  const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement | null>(null);
  const isLoading = useSelector((state: any) => state.productData.loadingColumnItems)
  const [isFull, setIsfull] = useState(false)
  const {
    selectedBlobTimeIncrement: timeIncrement,
    bgTables,
  } = useSelector((state: any) => state.productData)

  const [checked, setChecked] = useState<any[]>(checkedOptions);
  const [options, setOptions] = useState([])
  const [searchWord, setSearchWord] = useState('')
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [hasUpdated, setHasUpdated] = useState(false)

  const loadColumnItems = useCallback(() => {
    // @ts-ignore
    dispatch(getColumnItems({
      bg_table_location: bgTables[timeIncrement] || '',
      column_name: column.name,
      start_index: options.length,
      search: searchWord
      // @ts-ignore
    })).then((r: any) => {
      if (r.payload && Array.isArray(r.payload)) {
        if (r.payload.length < 1) {
          setIsfull(true)
        } else {
          const innerOptions = [...options]
          // @ts-ignore
          setOptions([...innerOptions.concat([...r.payload])])
        }
      }
    })
  }, [
    bgTables,
    timeIncrement,
    options,
    searchWord
  ])

  const scrollHandler = useCallback(() => {
    if (!ref.current) return
    if (
      options.length > 0 &&
      ref.current.scrollTop + ref.current.clientHeight >=
      ref.current.scrollHeight
    ) {
      if (!isFull && !isLoading && !isLoading) {
        loadColumnItems()
      }
    }
  }, [
    options.length,
    ref,
    isFull,
    isLoading,
    dispatch,
  ]);

  const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(e.target.value)
    setOptions([])
  }

  const handleToggle = (value: any) => {
    const currentIndex = checked.indexOf(value);
    const newChecked: any[] = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (!hasUpdated) setHasUpdated(true)
    setChecked(newChecked);
    dispatch(setProductOpt({
      path: 'currentPage',
      value: 0
    }))
    dispatch(setProductOpt({
      path: `filters.${column.name}`,
      value: newChecked
    }))
  }

  const handleFieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    loadColumnItems()
  };
  const handlePopoverClose = () => {
    setHasUpdated(false)
    setAnchorEl(null);
    setOptions([])
    setSearchWord('')
    setIsfull(false)
  };
  const openPop = Boolean(anchorEl);


  useEffect(() => {
    loadColumnItems()
  }, [
    searchWord
  ]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("scroll", scrollHandler)
    }
    return () => {
      if (ref.current && ref.current.removeEventListener) {
        ref.current.removeEventListener('scroll', scrollHandler)
      }
    }
  }, [
    ref
  ]);

  return <Box {...rest}>
    {
      label && label !== '' && (
        <Typography className={classes.Txt} mt={1}>{label}</Typography>
      )
    }
    <Box
      display="flex"
      sx={{
        border: 'solid 1px #dfd4d4',
        borderRadius: '4px',
        overflow: 'hidden',
        cursor: 'pointer',
        height: '38px',
        alignItems: 'center',
      }}
      onClick={handleFieldClick}
    >
      <Box flex={1}
           display="flex"
           sx={{
             height: '100%',
             alignItems: 'center',
           }}
      >
        <Box sx={{
          height: '100%',
          width: '9px',
          background: '#1976d2',
          marginRight: '8px'
        }}></Box>
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          {
            placeholder && (
              <span>{placeholder}</span>
            )
          }
          {
            column?.count && (
              <span style={{
                fontSize: '11px',
                fontWeight: checkedOptions.length > 0 ? 'bold' : 'normal',
                textTransform: 'capitalize'
              }}>{column?.count || 'N/A'}</span>
            )
          }
        </Box>
      </Box>
      <ArrowDropDown/>
    </Box>
    <Popover
      open={openPop}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      // transformOrigin={{
      //     vertical: 'bottom',
      //     horizontal: 'left',
      // }}
      disableRestoreFocus
    >
      <Box sx={{width: '470px', height: '300px', bgcolor: 'background.paper', flexFlow: 'column'}} display={'flex'}>
        <Box display="flex" sx={{position: 'relative'}}>
          <input
            className={classes.Input}
            placeholder='Search Items...'
            value={searchWord}
            onChange={handleSearchInput}
          />
          {
            searchWord !== '' && isLoading ?
              <CircularProgress sx={{position: 'absolute', top: '16px', right: '17px', color: '#bfbfbf'}} size={21}
                                color="secondary"/> :
              // <CircularProgress sx={{ position: 'absolute', top: '16px', right: '17px', color: '#bfbfbf', width: '21px', height: '21px' }} color="secondary" />
              <SearchIcon sx={{position: 'absolute', top: '16px', right: '17px', color: '#bfbfbf'}}/>
          }
        </Box>
        <Box display="flex" flex={1} sx={{borderTop: 'solid 1px #dddddd', overflow: 'hidden'}}>
          <Box ref={ref} flex={1} sx={{height: '100%', overflow: 'auto', position: 'relative'}}
               onScroll={scrollHandler}>
            {isLoading && (<Box sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center'
            }}>
              <CircularProgress sx={{margin: '100px auto', display: 'block'}} color="secondary"/>
            </Box>)}
            <nav>
              <List>
                {
                  options.slice(0, 100).map((item, idx) => {
                    const itemValue: any = item[column.name]
                    const value = typeof itemValue === 'object' && itemValue !== null && 'value' in itemValue
                      ? itemValue?.value
                      : itemValue
                    return <ListItem key={idx} disablePadding>
                      <ListItemButton
                        classes={{root: classes.ListItemButton}}
                        onClick={() => {
                          handleToggle(value)
                        }}
                      >
                        <ListItemIcon sx={{minWidth: 'auto'}}>
                          <Checkbox
                            edge="start"
                            checked={checked.includes(value)}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{'aria-labelledby': value}}
                          />
                        </ListItemIcon>
                        <ListItemText
                          classes={{primary: classes.ListItemText}}
                          primary={value}
                        />
                        <ChevronRight/>
                      </ListItemButton>
                    </ListItem>
                  })
                }
              </List>
            </nav>
          </Box>
          <Box flex={1} sx={{height: '100%', boxShadow: '-6px -4px 20px -9px #d1caca', paddingLeft: 1, paddingTop: 1}}>
            {
              checked.map(item => (
                <Chip
                  label={item}
                  onClick={() => {
                  }}
                  onDelete={() => handleToggle(item)}
                  sx={{marginBottom: '10px', marginRight: 1}}
                />
              ))
            }
          </Box>
        </Box>
      </Box>
    </Popover>
  </Box>
};
