import React, {useState, useMemo, useEffect} from 'react';

import {
  Grid,
  Box,
  Button, Chip,
} from "@mui/material";
import {FilterSelect} from "components/common/Product/FilterSelect";
import SeriesAccordion from '../../details/series/SeriesAccordion'
import {useDispatch, useSelector} from "react-redux";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {addSeries, setProductOpt, setXAxis, updateSeries} from "store/slices/StockProductSlice";
import {useProductContext} from "providers/product/ProductProvider";

const DefaultPanel = () => {
  const dispatch = useDispatch()
  const { columns, filters } = useSelector(state => state.productData)

  const removeFilterOpt = (key, value) => {
    const options = [...(filters[key] || [])]
    const currentIndex = options.indexOf(value);
    if(currentIndex > -1){
      options.splice(currentIndex, 1);

      dispatch(setProductOpt({
        path: 'currentPage',
        value: 0
      }))
      dispatch(setProductOpt({
        path: `filters.${key}`,
        value: options
      }))
    }
  }

  return (
    <Grid item lg={12} md={12} xs={12}>
      <Box>
        {
          columns.map((item, idx) => (
            <FilterSelect
              key={idx}
              column={item}
              checkedOptions={filters[item.name] || []}
              onChange={(val) => dispatch(setXAxis(val))}
              placeholder={item.name}
              style={{ marginBottom: '10px' }}
            />
          ))
        }
      </Box>
      {
        Object.keys(filters)?.map(idx => {
          return <Box>
            <h4 style={{
              fontSize: '14px',
              textTransform: 'uppercase',
              marginBottom: 4
            }}>{ idx.split('_').join(' ') }</h4>
            {
              filters[idx].map(item => (
                <Chip
                  label={item}
                  onClick={() => {
                  }}
                  onDelete={() => removeFilterOpt(idx, item)}
                  sx={{marginBottom: '10px', marginRight: 1}}
                />
              ))
            }
          </Box>
        })
      }
    </Grid>
  )
};

export default DefaultPanel