import React, {useState, useMemo} from "react";
import {Box} from "@mui/system";
import {Tooltip, Typography} from "@mui/material";
import {Autorenew, TrendingUp as TrendingUpIcon, Source as SourceIcon, Settings as SettingIcon} from "@mui/icons-material";
import FavoriteButton from "components/common/Favorites/FavoriteButton";
import useRunIndividualQA from "hooks/RunIndividualQA";
import {useProductContext} from "providers/product/ProductProvider";
import {useDispatch, useSelector} from "react-redux";
import { makeStyles } from "@mui/styles";
import {setProductOpt} from "store/slices/StockProductSlice";
import TimeIncrementsBox from "./TimeIncrementsBox";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const useStyles = makeStyles(() => ({
  productStaticBarButton: {
    width: 40,
    height: 40,
    cursor: 'pointer',
    padding: 3,
    position: 'relative',
    transition: 'all ease-in .2s',
    borderRadius: '4px',
    marginRight: '12px',
    boxShadow: '0 0 1px 1px #e5e5f1',
    display: 'flex',
    flexFlow: 'column',
    color: '#4e4b4b',
    '&.selected': {
      boxShadow: '0 0 1px 1px #646467'
    },
    '&:hover': {
      background: '#ececec'
    },
    '&.selectedViewMode': {
      background: '#3c54db',
      color: 'white',
      '&:hover': {
        background: '#5e72e4'
      },
    },
    '& svg': {
      display: 'block',
      margin: '0 auto',
      fontSize: '35px'
    },
    // '&::after': {
    //     content: '""',
    //     position: 'absolute',
    //     height: '1px',
    //     borderTop: 'solid 1px #e2dcdc',
    //     bottom: '-6px',
    //     width: 'calc(100% - 6px)'
    // },
    // '&:last-child': {
    //     '&::after': {
    //         content: 'initial'
    //     }
    // }
  },
  '@keyframes rotate-circle': {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  QualityAnaylicCap: {
    // fontSize: '34px',
    padding: '5px 9px',
    lineHeight: 1,
    fontSize: '24px'
  },
  QualityAnaylicProgress: {
    // fontSize: '39px !important',
    // padding: 0,
    fontSize: '27px !important',
    margin: '3px !important',
    animation: '$rotate-circle 2s linear infinite',
  }
}))

export const SidebarHeader = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [qaMode, setQaMode] = useState();
  const [showQAResult, setShowQAResult] = useState(false)
  const viewMode = useSelector(state => state.productData.viewMode)
  const bgTables = useSelector(state => state.productData.bgTables)
  const { feedSource, databaseCode, productCode, beaDatabaseCode, feedSourceType } = useProductContext()

  const [triggerIndividualQA, {
    isLoading: inMeasuring, qaResults = []
  }] = useRunIndividualQA(dispatch, () => {
    setShowQAResult(true)
  });
  const clickMeasurePerformance = async () => {
    triggerIndividualQA({
      feedSource,
      databaseCode,
      productCode
    })
  }

  const hasBigQuery = useMemo(() => {
    const locations = Object.values(bgTables)
    return locations.length > 0 && !(locations.includes('') || locations.includes(null))
  }, [
    bgTables
  ])

  const handleCloseQAResultDialog = () => {
    setShowQAResult(false)
  }


  return <Box display="flex" sx={{ gap: 1, flexFlow: 'column' }}>
      <Box display="flex" sx={{ alignItems: 'center', flex: 1 }}>
        <Box>
          <Tooltip title="Start Quality Analytic">
            {/* <a
          href="#"
          onClick={clickMeasurePerformance}
          disabled={inMeasuring}
      >
          <PlayArrow />
      </a> */}
            <a

              href="#"
              onClick={clickMeasurePerformance}
              disabled={inMeasuring}
              className={`${classes.productStaticBarButton}`}>
              {
                inMeasuring ?
                  <Autorenew className={classes.QualityAnaylicProgress}/> :
                  <Typography className={classes.QualityAnaylicCap}>Q</Typography>
              }
            </a>
          </Tooltip>
        </Box>
        <FavoriteButton
          isBeaDatabaseCode={beaDatabaseCode !== ''}
          feedSourceType={feedSourceType}
        />
        {
          hasBigQuery && <Box onClick={() => {
            dispatch(setProductOpt({
              path: 'viewMode',
              value: 'filter'
            }))
          }}>

            <Tooltip title={'Filter'}>
              <a href="#"
                 className={`${classes.productStaticBarButton} ${viewMode === 'filter' && 'selectedViewMode'}`}>
                <FilterAltIcon />
                <span></span>
              </a>
            </Tooltip>
          </Box>
        }
        <Box onClick={() => {
          dispatch(setProductOpt({
            path: 'viewMode',
            value: 'datatable'
          }))
        }}>

          <Tooltip title={'Data'}>
            <a href="#"
               className={`${classes.productStaticBarButton} ${viewMode === 'datatable' && 'selectedViewMode'}`}>
              <SourceIcon />
              <span></span>
            </a>
          </Tooltip>
        </Box>
        <Box onClick={() => {
          dispatch(setProductOpt({
            path: 'viewMode',
            value: 'chart'
          }))
        }}>

          <Tooltip title={'Chart Type'}>
            <a href="#"
               className={`${classes.productStaticBarButton} ${viewMode === 'chart' && 'selectedViewMode'}`}>
              <TrendingUpIcon />
              <span></span>
            </a>
          </Tooltip>
        </Box>
        <Box onClick={() => {
          dispatch(setProductOpt({
            path: 'viewMode',
            value: 'customize'
          }))
        }}>
          <Tooltip title={'Customize'}>
            <a href="#"
               className={`${classes.productStaticBarButton} ${viewMode === 'customize' && 'selectedViewMode'}`}>
              <SettingIcon />
              <span></span>
            </a>
          </Tooltip>
        </Box>
      </Box>
    {
      (beaDatabaseCode === '' || qaMode) && (
        <TimeIncrementsBox />
      )
    }
  </Box>
}