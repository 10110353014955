import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from 'store/services/AxiosService';
import { paramsToQueryParam } from 'store/UrlUtils';
import {
  EOD_FETCH_BEA_MNE_CLASSIFICATIONS,
  EOD_FETCH_BEA_MNE_DIRECTIONS,
  EOD_FETCH_BEA_MNE_INVESTMENTS,
  EOD_FETCH_BEA_MNE_PRODUCT_DATA,
  EOD_FETCH_BEA_MNE_SERIES,
  EOD_FETCH_BG_STOCK_PRODUCT,
  EOD_FETCH_BQ_DATA_FOR_TRANSFORM,
  EOD_FETCH_CS_DATA_FOR_TRANSFORM,
  EOD_FETCH_PRODUCT_DATA,
  EOD_FETCH_PRODUCT_SQL, EOD_GET_COLUMN_ITEMS,
  EOD_GET_PRODUCT_COLUMNS,
  EOD_SAVE_CHART
} from 'store/UrlConstants';
import { setPath } from 'shared/utilities/helpers'
import axios from 'axios';
import { showSnackbarMessage } from './MessagesSystemSlice';
import pako from 'pako'

var CancelToken = axios.CancelToken;
let fetchProductSQLSource;
let fetchColumnItemsSource;


export const fetchProductSQL = createAsyncThunk('eod/fetch_stock_product_sql', async (params, thunkAPI) => {
  if (fetchProductSQLSource) {
    console.log(params, 'in params')
    console.log('cancelled???')
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();
  console.log(params, 'params')
  try {
    const response = await API.get(EOD_FETCH_PRODUCT_SQL + paramsToQueryParam(params), {
      cancelToken: fetchProductSQLSource.token
    });
    return {
      feedSource: params.feed_source,
      databaseCode: params.database_code,
      productCode: params.product_code,
      product: response.data.product,
      charts: response.data.charts || [],
    };
  } catch (e) {
    if (!API.isCancel(e)) {
      thunkAPI.dispatch(showSnackbarMessage({
        message: `Something went wrong in loading data. Please kindly try again later`,
        severity: 'error'
      }))
    }
    return thunkAPI.rejectWithValue(e.response.data)
  }
});
export const fetchStockProductOfBigQuery = createAsyncThunk('eod/fetch_bg_stock_product', async (params, thunkAPI) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();
  let identifier = `bg/${params.feed_source}/${params.database_code}/${params.product_code}`
  if (params.timeIncrement) {
    identifier += `/${params.timeIncrement}`
  }
  try {
    const response = await API.get(EOD_FETCH_BG_STOCK_PRODUCT + paramsToQueryParam({
      bg_table_location: params.bg_table_location,
      filters: params.filters,
      page: params.page,
      pageSize: params.pageSize,
      viewMode: params.viewMode
    }), {
      cancelToken: fetchProductSQLSource.token
    });
    if (response.data) {
      return {
        identifier,
        ...response.data
      };
    } else {
      if (API.isCancel(response)) {
        return thunkAPI.rejectWithValue(response.data)
      }
      let errorMsg
      if (response?.response?.data) {
        errorMsg = response.response.data
      } else {
        errorMsg = `Something went wrong. Please kindly try again later`
      }
      if (errorMsg !== '') {
        thunkAPI.dispatch(showSnackbarMessage({
          message: errorMsg,
          severity: 'error'
        }))
        return thunkAPI.rejectWithValue(errorMsg)
      }
      return {
        identifier,
        data: []
      };
    }
  } catch (e) {
    if (!API.isCancel(e)) {
      thunkAPI.dispatch(showSnackbarMessage({
        message: `Something went wrong. Please kindly try again later`,
        severity: 'error'
      }))
    }
    return thunkAPI.rejectWithValue(e.response.data)
  }
});
export const getProductColumns = createAsyncThunk(EOD_GET_PRODUCT_COLUMNS, async (params, thunkAPI) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();
  let identifier = `bg/${params.feed_source}/${params.database_code}/${params.product_code}`
  if (params.timeIncrement) {
    identifier += `/${params.timeIncrement}`
  }
  try {
    const response = await API.get(EOD_GET_PRODUCT_COLUMNS + paramsToQueryParam({
      bg_table_location: params.bg_table_location
    }), {
      cancelToken: fetchProductSQLSource.token
    });
    if (response.data) {
      return {
        identifier,
        columns: response.data
      };
    } else {
      if (API.isCancel(response)) {
        return thunkAPI.rejectWithValue(response.data)
      }
      let errorMsg
      if (response?.response?.data) {
        errorMsg = response.response.data
      } else {
        errorMsg = `Something went wrong. Please kindly try again later`
      }
      if (errorMsg !== '') {
        thunkAPI.dispatch(showSnackbarMessage({
          message: errorMsg,
          severity: 'error'
        }))
        return thunkAPI.rejectWithValue(errorMsg)
      }
      return {
        identifier,
        columns: []
      };
    }
  } catch (e) {
    if (!API.isCancel(e)) {
      thunkAPI.dispatch(showSnackbarMessage({
        message: `Something went wrong. Please kindly try again later`,
        severity: 'error'
      }))
    }
    return thunkAPI.rejectWithValue(e.response.data)
  }
});
export const getColumnItems = createAsyncThunk(EOD_GET_COLUMN_ITEMS, async (params, thunkAPI) => {
  if (fetchColumnItemsSource) {
    fetchColumnItemsSource.cancel()
  }
  fetchColumnItemsSource = CancelToken.source();
  try {
    const response = await API.get(EOD_GET_COLUMN_ITEMS + paramsToQueryParam(params), {
      cancelToken: fetchColumnItemsSource.token
    });
    return response?.data || []
  } catch (e) {
    if (!API.isCancel(e)) {
      thunkAPI.dispatch(showSnackbarMessage({
        message: `Something went wrong. Please kindly try again later`,
        severity: 'error'
      }))
    }
    return thunkAPI.rejectWithValue(e.response.data)
  }
});
export const fetchStockProductData = createAsyncThunk('eod/product_stock_values', async (params, thunkAPI) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();
  let identifier = `${params.feed_source}/${params.database_code}/${params.product_code}`
  if (params.timeIncrement) {
    identifier += `/${params.timeIncrement}`
  }
  try {
    let url = EOD_FETCH_PRODUCT_DATA + `/${params.feed_source}/${params.database_code}/${params.product_code}`
    if (params.timeIncrement) {
      url += `/${params.timeIncrement}`
    }
    const { data: signedUrl } = await API.get(url, {
      cancelToken: fetchProductSQLSource.token
    });
    let result = {
      data: [],
      identifier
    }
    if (signedUrl) {
      let fileResponse = {};
      if (signedUrl.includes(".json.gz?X-Goog-Algorithm")) {
        try {
          await axios.get(signedUrl, {
            cancelToken: fetchProductSQLSource.token,
            responseType: 'arraybuffer'
          })
            .then(response => {
              const buffer = Buffer.from(response.data);
              fileResponse.data = pako.ungzip(buffer, { to: 'string' });
              // console.log(jsonData, 'jsonData')
            })
        } catch (e) {
          console.log(e, 'error in gzip')
        }
      } else {
        fileResponse = await axios.get(signedUrl, {
          cancelToken: fetchProductSQLSource.token
        })
      }
      if (fileResponse?.data) {
        let fileResponseData = fileResponse.data
        let dataPoints = []
        if (typeof fileResponseData === 'object') {
          dataPoints.push(fileResponseData)
        } else if (typeof fileResponseData === 'string') {
          try {
            fileResponseData = fileResponseData.replaceAll(`}{`, `},{`)
            dataPoints = JSON.parse(`[${fileResponseData.split(/\r?\n/).filter(r => r).join(',')}]`)
          } catch (e) {
            console.log(e, 'gzip jsondata parse')
          }
        }
        console.log(dataPoints?.length, 'datapoints length')
        result = {
          data: dataPoints,
          identifier
        };
      } else {
        let errorMsg = ''
        if (fileResponse?.response?.status === 404) {
          errorMsg = `File's not found on the google cloud storage`
        } else {
          if (!API.isCancel(fileResponse)) {
            errorMsg = `Something went wrong. Please kindly try again later`
          }
        }
        if (errorMsg !== '') {
          thunkAPI.dispatch(
            showSnackbarMessage({
              message: errorMsg,
              severity: 'error'
            })
          )
          return thunkAPI.rejectWithValue(errorMsg)
        }
      }
    }
    return result
  } catch (e) {
    console.log(e.response, 'fetchStockProductData')
    console.log(e.response.data, 'fetchStockProductData')
    return thunkAPI.rejectWithValue(e.response.data)
  }
});

export const fetchStockProductSignedUrl = createAsyncThunk('eod/product_stock_product_signed_url', async (params, thunkAPI) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();
  // let identifier = `${params.feed_source}/${params.database_code}/${params.product_code}`
  // if (params.timeIncrement) {
  //   identifier += `/${params.timeIncrement}`
  // }
  try {
    let url = EOD_FETCH_PRODUCT_DATA + `/${params.feed_source}/${params.database_code}/${params.product_code}`
    if (params.timeIncrement) {
      url += `/${params.timeIncrement}`
    }
    const { data: signedUrl } = await API.get(url, {
      cancelToken: fetchProductSQLSource.token
    });
    return {
      signedUrl,
      timeIncrement: params.timeIncrement || ''
    }
  } catch (e) {
    return thunkAPI.rejectWithValue(e.response.data)
  }
});
export const fetchStockProductDataForTransform = createAsyncThunk('eod/fetch_cs_data_for_transform', async (params) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();

  const response = await axios({
    method: 'post',
    url: EOD_FETCH_CS_DATA_FOR_TRANSFORM,
    cancelToken: fetchProductSQLSource.token,
    data: { ...params }
  });
  let identifier = `${params.feedSource}/${params.databaseCode}/${params.productCode}`
  return {
    data: response.data,
    identifier
  };
})
export const fetchBQDataForTransform = createAsyncThunk('eod/fetch_bq_data_for_transform', async (params) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();

  const response = await axios({
    method: 'post',
    url: EOD_FETCH_BQ_DATA_FOR_TRANSFORM,
    cancelToken: fetchProductSQLSource.token,
    data: { ...params }
  });
  let identifier = `bg/${params.feedSource}/${params.databaseCode}/${params.productCode}`
  return {
    data: response.data,
    identifier
  };
})


export const fetchBeaMneInvestments = createAsyncThunk('eod/fetch_bea_mne_investments', async () => {
  const response = await axios({
    method: 'get',
    url: EOD_FETCH_BEA_MNE_INVESTMENTS
  });
  return response.data
})

export const fetchBeaMneDirections = createAsyncThunk('eod/fetch_bea_mne_directions', async () => {
  const response = await axios({
    method: 'get',
    url: EOD_FETCH_BEA_MNE_DIRECTIONS,
  });
  return response.data
})

export const fetchBeaMneSeries = createAsyncThunk('eod/fetch_bea_mne_series', async (params) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();

  const response = await axios({
    method: 'get',
    url: EOD_FETCH_BEA_MNE_SERIES + paramsToQueryParam(params),
    cancelToken: fetchProductSQLSource.token,
  });
  return response.data
})
export const fetchBeaMneClassifications = createAsyncThunk('eod/fetch_bea_mne_classifications', async (params) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();

  const response = await axios({
    method: 'get',
    url: EOD_FETCH_BEA_MNE_CLASSIFICATIONS + paramsToQueryParam(params),
    cancelToken: fetchProductSQLSource.token,
  });
  return response.data
})
export const fetchBeaMneProductData = createAsyncThunk('eod/fetch_bea_mne_product_data', async (params) => {
  if (fetchProductSQLSource) {
    fetchProductSQLSource.cancel()
  }
  fetchProductSQLSource = CancelToken.source();

  const response = await axios({
    method: 'get',
    url: EOD_FETCH_BEA_MNE_PRODUCT_DATA
      + paramsToQueryParam(params),
    cancelToken: fetchProductSQLSource.token,
  });
  return response.data
})


export const saveChart = createAsyncThunk('eod/save_chart', async (params) => {
  const response = await axios({
    method: 'post',
    url: EOD_SAVE_CHART,
    data: { ...params }
  });
  return response.data;
})

export const initialChart =  {
  annotations: [],
  categoryField: null,
  chartSettings: {
    axes: {
      horizontal: {
        input: '{value:%b %e, %Y}',
        select: '{value:%b %e, %Y}',
        visible: true,
        opposite: false,
        reversed: false,
        style: {
          fontWeight: 'normal',
          fontStyle: 'normal',
          color: 'black'
        }
      },
      vertical: {
        input: '{value}',
        select: '{value}',
        visible: true,
        opposite: true,
        reversed: false,
        style: {
          fontWeight: 'normal',
          fontStyle: 'normal',
          color: 'black'
        }
      }
    },
    chart: {
      backgroundColor: 'white',
    },
    tooltip: {
      pointFormatHeader: `{point.key}`,
      pointFormatBody: `{series.name}: {point.y}`
    },
    legend: {
      enabled: true,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'bottom',
      itemStyle: {
        fontWeight: 'normal',
        fontStyle: 'normal',
        color: 'black'
      },
      borderColor: 'transparent',
      borderRadius: 1,
      borderWidth: 1,
    }
  },
  dataLabelsData: {
    'all': {
      enabled: false,
      select: '{y}',
      format: '{y}',
      borderColor: 'transparent',
      borderRadius: 1,
      borderWidth: 1,
    }
  },
  marketChartType: 'line',
  nonMarketChartType: 'line',
  plotHighlights: [],
  selectedDataOpt: null,
  series: [],
  tooltipFormatData: {
    'all': {
      pointFormatHeader: `{point.key}`,
      pointFormatBody: `{series.name}: {point.y}`
    }
  },
  xAxis: null,
  yAxis: null,
}

const initialProductData = {
  ...initialChart,
  activeChartAnchor: null,
  timeIncrements: null,
  loadedProductData: {},
  bgTables: {},
  timeframes: {},
  productName: null,
  rootErrorMsg: '',
  errorMsg: '',
  displaySchemas: {},
  selectedFrequencies: [],
  selectedYears: [],
  selectedLines: [],
  selectedAreas: [],
  signedUrls: {},
  loadingStockData: true,
  loadingTableData: true,
  loadingProductColumns: true,
  loadingColumnItems: true,
  loadingInProgress: true,
  fields: [],
  columns: [],
  filters: {},
  xAxisFields: [],
  yAxisFields: [],
  selectedChartTimeIncrement: null,
  selectedBlobTimeIncrement: null,
  selectedChartLines: null,
  loadingSaveChart: false,
  charts: {},
  selectedChart: '',

  currentData: [],
  currentPage: 0,
  rowsPerPage: 500,
  totalRecords: 0
}

const initialState = {
  qaMode: false,
  viewMode: 'filter',
  nonMarket: {},
  showSidebar: true,

  hiddenColumns: {},
  timeRange: '5y',

  fields: [],

  feedSourceName: null,
  databaseCodeName: null,

  selectedSeries: [],
  selectedColumns: [],
  selectedRows: [],

  beaMneInvestments: [],
  beaMneDirections: [],
  beaMneSeries: [],
  beaMneClassifications: [],
  loadingBeaMne: false,
  ...initialProductData
};

const stockProductSlice = createSlice({
  name: 'stockProduct',
  initialState,
  reducers: {
    setProductOpt: (state, action) => {
      const { path, value } = action.payload
      setPath(state, path, value)
    },
    setFields: (state, action) => {
      state.fields = [...action.payload]
    },
    setXAxisFields: (state, action) => {
      state.xAxisFields = [...action.payload]
    },
    deleteChartColors: (state, action) => {
      const idx = action.payload
      if (state?.chartSettings?.colors?.length) {
        console.log(idx, 'idx')
        state.chartSettings.colors.splice(idx, 1)
      }
    },
    clearSeries: (state) => {
      state.series = []
    },
    addSeries: (state, action) => {
      state.series.push({
        id: new Date().getTime(),
        ...action.payload
      })
    },
    updateSeries: (state, action) => {
      const { id, field, newValue } = action.payload;

      const idx = state.series.findIndex(item => item.id === id);
      if(idx > -1){
        state.series[idx][field] = newValue;
      }
    },
    deleteSeries: (state, action) => {
      const id  = action.payload
      state.series = state.series.filter(item => item.id !== id)
    },
    clearAllSeries: (state) => {
      state.series = []
    },

    addAnnotation: (state, action) => {
      state.annotations.push({
        id: new Date().getTime(),
        ...action.payload
      })
    },
    updateAnnotation: (state, action) => {
      const { id, field, newValue } = action.payload;

      const idx = state.annotations.findIndex(item => item.id === id);
      if(idx > -1){
        state.annotations[idx][field] = newValue;
      }
    },
    deleteAnnotation: (state, action) => {
      const id  = action.payload
      state.annotations = state.annotations.filter(item => item.id !== id)
    },
    addPlotHighlights: (state, action) => {
      state.plotHighlights.push({
        id: new Date().getTime(),
        ...action.payload
      })
    },
    updatePlotHighlights: (state, action) => {
      const { id, path, newValue } = action.payload;
      const idx = state.plotHighlights.findIndex(item => item.id === id);
      if(idx > -1){
        setPath(state, `plotHighlights.${idx}.${path}`, newValue)
      }
    },
    deletePlotHighlights: (state, action) => {
      const id  = action.payload
      state.plotHighlights = state.plotHighlights.filter(item => item.id !== id)
    },

    setYAxisFields: (state, action) => {
      state.yAxisFields = [...action.payload]
    },
    setXAxis: (state, action) => {
      state.xAxis = action.payload
    },
    setYAxis: (state, action) => {
      state.yAxis = action.payload
    },
    clearStockProductData: () => {
      // state.productData = {};
      // state.loadingInProgress = false
    },
    setLoadedProductData: (state, action) => {
      const { identifier, productData } = action.payload
      let _data = state.loadedProductData
      if (!_data) {
        _data = {}
      }
      _data[identifier] = JSON.parse(JSON.stringify(productData))
      state.loadedProductData = _data
    },
    initProductMetaData: (state) => {
      for(let field in initialProductData){
        state[field] = initialProductData[field]
      }
    },
    setTimeRange: (state, action) => {
      state.timeRange = action.payload
    },

    setSelectedYears: (state, action) => {
      state.selectedYears = action.payload;
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setSelectedColumns: (state, action) => {
      state.selectedColumns = action.payload;
    },
    setSelectedSeries: (state, action) => {
      state.selectedSeries = action.payload;
    },
    setSelectedLines: (state, action) => {
      state.selectedLines = action.payload;
    },
    setSelectedAreas: (state, action) => {
      state.selectedAreas = action.payload;
    },
    setSelectedFrequencies: (state, action) => {
      state.selectedFrequencies = action.payload;
    },
    setSelectedBlobTimeIncrement: (state, action) => {
      state.selectedBlobTimeIncrement = action.payload
    },
    setSelectedChartTimeIncrement: (state, action) => {
      state.selectedChartTimeIncrement = action.payload
    },
    setSelectedChartLines: (state, action) => {
      state.selectedChartLines = action.payload
    },
    setSelectedDataOpt: (state, action) => {
      state.selectedDataOpt = action.payload
    },
    setInitialChartConfigs: (state, action) => {
      for (let key in action.payload){
        state[key] = action.payload[key]
      }
    }
  },
  extraReducers: {
    [fetchProductSQL.pending]: (state) => {
      state.loadingInProgress = true
    },
    [fetchProductSQL.fulfilled]: (state, action) => {
      state.loadingInProgress = false
      if (action.payload) {
        const { payload: {
          product,
          productCode: loadedProductCode,
          feedSource: feedSourceName,
          databaseCode: databaseCodeName,
          charts = {}
        } } = action
        state.charts = charts
        if (product) {
          const {
            product_code,
            titles = [],
            valid_times = [],
            bigquery_table_locations: bgTables,
            display_schemas = [],
            from_dates,
            to_dates,
            last_updates,
            last_load_attempts,
            first_addeds
          } = product
          let _title = '';
          const timeframes = {}
          state.timeIncrements = [...valid_times]
          // if(valid_times == [""]){
          //   if(display_schemas.length > 0){
          //     state.displaySchemas[""] = display_schemas[0]
          //   }
          // }else{
          valid_times.map((item, idx) => {
            if (bgTables[idx]) {
              state.bgTables[item] = bgTables[idx]
            }
            if (display_schemas[idx]) {
              state.displaySchemas[item] = display_schemas[idx]
            }
            if (titles[idx] && titles[idx] !== 'null' && _title === '') {
              _title = titles[idx]
            }
            if(!timeframes[item]){
              timeframes[item] = {}
            }
            timeframes[item].from_date = from_dates[idx]
            timeframes[item].to_date = to_dates[idx]
            timeframes[item].last_update = last_updates[idx]
            timeframes[item].last_load_attempt = last_load_attempts[idx]
            timeframes[item].first_added = first_addeds[idx]

          })
          state.timeframes = timeframes
          state.productName = _title || product_code || ' '
          // }
        } else if (loadedProductCode) {
          state.productName = loadedProductCode || ' '
          state.timeIncrements = null
          state.displaySchemas = {}
        }
        state.feedSourceName = feedSourceName
        state.databaseCodeName = databaseCodeName
      }
    },
    [fetchProductSQL.rejected]: (state) => {
      state.loadingInProgress = false
      state.rootErrorMsg = 'Error Happened in loading the Product Data'
    },
    [fetchStockProductData.pending]: (state) => {
      state.loadingStockData = true
    },
    [fetchStockProductData.fulfilled]: (state, action) => {
      if (action.payload) {
        const { data, identifier } = action.payload
        if (data) {
          let _loadedProductData = state.loadedProductData || {}
          if (Array.isArray(data)) {
            _loadedProductData[identifier] = data
          } else {
            _loadedProductData[identifier] = [data]
          }
          state.totalRecords = data?.length || 0
          state.loadedProductData = { ..._loadedProductData }
        }
      }
      state.loadingStockData = false
    },
    [fetchStockProductData.rejected]: (state, action) => {
      state.errorMsg = action.payload
      state.loadingStockData = false
    },
    [fetchStockProductDataForTransform.pending]: (state) => {
      state.loadingStockData = true
    },
    [fetchStockProductDataForTransform.fulfilled]: (state, action) => {
      const { data, identifier } = action.payload
      if (data) {
        state.loadedProductData[identifier] = data
      }
      state.loadingStockData = false
    },
    [fetchStockProductDataForTransform.rejected]: (state, action) => {
      state.errorMsg = action.payload
      state.loadingStockData = false
    },
    [fetchBQDataForTransform.pending]: (state) => {
      state.loadingStockData = true
    },
    [fetchBQDataForTransform.fulfilled]: (state, action) => {
      const { data, identifier } = action.payload
      if (data) {
        const loadedProductData = {
          areas: data.areas,
          data: data.data,
          lines: data.lines
        }
        if (!data.hasYears) {
          loadedProductData.years = data.years
        }
        state.loadedProductData[identifier] = {
          ...state.loadedProductData[identifier],
          ...loadedProductData
        }
      }
      state.loadingStockData = false
    },
    [fetchBQDataForTransform.rejected]: (state, action) => {
      state.errorMsg = action.payload
      state.loadingStockData = false
    },
    [fetchStockProductSignedUrl.pending]: (state) => {
      state.loadingStockData = true
    },
    [fetchStockProductSignedUrl.fulfilled]: (state, action) => {
      const { signedUrl, timeIncrement } = action.payload
      if (signedUrl) {
        state.signedUrls[timeIncrement] = signedUrl
      }
    },
    [fetchStockProductSignedUrl.rejected]: (state, action) => {
      state.errorMsg = action.payload
      state.loadingStockData = false
    },
    [fetchStockProductOfBigQuery.pending]: (state) => {
      state.loadingTableData = true
    },
    [fetchStockProductOfBigQuery.fulfilled]: (state, action) => {
      if (action.payload && action.payload?.data) {
        const {
          data,
          identifier,
          columns,
          pagination
        } = action.payload
        let _loadedProductData = state.loadedProductData || {}
        if (Array.isArray(data)) {
          _loadedProductData[identifier] = data
        } else {
          _loadedProductData[identifier] = [data]
        }
        state.totalRecords = pagination.totalRows
        state.loadedProductData = { ..._loadedProductData }
      }
      state.loadingTableData = false
      state.loadingStockData = false
    },
    [fetchStockProductOfBigQuery.rejected]: (state, action) => {
      state.loadingTableData = false
      state.loadingStockData = false
      state.errorMsg = action.payload
    },
    [getProductColumns.pending]: (state) => {
      state.loadingProductColumns = true
    },
    [getProductColumns.fulfilled]: (state, action) => {
      if (action.payload && action.payload?.columns) {
        const {
          identifier,
          columns,
        } = action.payload
        state.columns = columns
      }
      state.loadingProductColumns = false
    },
    [getProductColumns.rejected]: (state, action) => {
      state.loadingProductColumns = false
      state.errorMsg = action.payload
    },
    [getColumnItems.pending]: (state) => {
      state.loadingColumnItems = true
    },
    [getColumnItems.fulfilled]: (state, action) => {
      state.loadingColumnItems = false
    },
    [getProductColumns.rejected]: (state, action) => {
      state.loadingColumnItems = false
      state.errorMsg = action.payload
    },

    [fetchBeaMneInvestments.pending]: (state) => {
      state.loadingBeaMne = true
    },
    [fetchBeaMneInvestments.fulfilled]: (state, action) => {
      if (action.payload) {
        state.beaMneInvestments = action.payload
      }
      state.loadingBeaMne = false
    },
    [fetchBeaMneInvestments.rejected]: (state, action) => {
      state.loadingBeaMne = false
      state.errorMsg = action.payload
    },
    [fetchBeaMneDirections.pending]: (state) => {
      state.loadingBeaMne = true
    },
    [fetchBeaMneDirections.fulfilled]: (state, action) => {
      if (action.payload) {
        state.beaMneDirections = action.payload
      }
      state.loadingBeaMne = false
    },
    [fetchBeaMneDirections.rejected]: (state, action) => {
      state.loadingBeaMne = false
      state.errorMsg = action.payload
    },
    [fetchBeaMneSeries.pending]: (state) => {
      state.loadingBeaMne = true
    },
    [fetchBeaMneSeries.fulfilled]: (state, action) => {
      if (action.payload) {
        state.beaMneSeries = action.payload
      }
      state.loadingBeaMne = false
    },
    [fetchBeaMneSeries.rejected]: (state, action) => {
      state.loadingBeaMne = false
      state.errorMsg = action.payload
    },
    [fetchBeaMneClassifications.pending]: (state) => {
      state.loadingBeaMne = true
    },
    [fetchBeaMneClassifications.fulfilled]: (state, action) => {
      if (action.payload) {
        state.beaMneClassifications = action.payload
      }
      state.loadingBeaMne = false
    },
    [fetchBeaMneClassifications.rejected]: (state, action) => {
      state.loadingBeaMne = false
      state.errorMsg = action.payload
    },
    [fetchBeaMneProductData.pending]: (state) => {
      state.loadingBeaMne = true
    },
    [fetchBeaMneProductData.fulfilled]: (state, action) => {
      state.loadingBeaMne = false
      if (action.payload) {
        const { payload: {
          productData: products,
          productCode: loadedProductCode
        } } = action
        if (products && products[0]) {
          const { product_code, titles = [], valid_times = [], bigquery_table_locations: bgTables, display_schemas = [] } = products[0]
          let _title = '';
          state.timeIncrements = [...valid_times]
          // if(valid_times == [""]){
          //   if(display_schemas.length > 0){
          //     state.displaySchemas[""] = display_schemas[0]
          //   }
          // }else{
          valid_times.map((item, idx) => {
            if (bgTables[idx]) {
              state.bgTables[item] = bgTables[idx]
            }
            if (display_schemas[idx]) {
              state.displaySchemas[item] = display_schemas[idx]
            }
            if (titles[idx] && titles[idx] !== 'null' && _title === '') {
              _title = titles[idx]
            }
          })
          state.productName = _title || product_code || ' '
          console.log(valid_times, 'valid_times')
          if (valid_times[0] !== undefined) {
            state.selectedFrequencies = [valid_times[0]]
          }
          console.log(valid_times, 'valid_times')
          // }
        } else if (loadedProductCode) {
          state.productName = loadedProductCode || ' '
          state.timeIncrements = null
          state.displaySchemas = {}
        }
        state.feedSourceName = 'BEA'
        state.databaseCodeName = 'MNE'
      }
    },
    [fetchBeaMneProductData.rejected]: (state, action) => {
      state.loadingBeaMne = false
      state.errorMsg = action.payload
    },
    [saveChart.pending]: (state) => {
      state.loadingSaveChart = true
    },
    [saveChart.fulfilled]: (state, action) => {
      if (action.payload) {
        console.log(action.payload)
      }
      state.loadingSaveChart = false
    },
    [saveChart.rejected]: (state, action) => {
      state.loadingSaveChart = false
      state.errorMsg = action.payload
    }
  },
});

export const prepareSelector = state => state.stockProduct;
export const {
  setProductOpt,
  setFields,
  setXAxisFields,
  setYAxisFields,
  setXAxis,
  setYAxis,
  clearStockProductData,
  setLoadedProductData,
  initProductMetaData,
  setTimeRange,
  setSelectedYears,
  setSelectedFrequencies,
  setSelectedLines,
  setSelectedAreas,
  setSelectedSeries,
  setSelectedColumns,
  setSelectedRows,
  setSelectedChartLines,
  setSelectedChartTimeIncrement,
  setSelectedBlobTimeIncrement,
  setSelectedDataOpt,
  clearSeries,
  addSeries,
  updateSeries,
  deleteSeries,
  clearAllSeries,
  deleteChartColors,
  addAnnotation,
  updateAnnotation,
  deleteAnnotation,
  addPlotHighlights,
  updatePlotHighlights,
  deletePlotHighlights,
  setInitialChartConfigs
} = stockProductSlice.actions
export default stockProductSlice.reducer;
