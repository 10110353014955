import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchStockProductData, fetchStockProductOfBigQuery, getProductColumns} from 'store/slices/StockProductSlice';

interface Filters {
  [key: string]: any;
}

interface BlobPath {
  feed_source: string;
  database_code: string;
  product_code: string;
  beaDatabaseCode?: string;
}

const useFetchCloudStorageData = (blobPath: BlobPath) => {
  const {
    qaMode,
    loadedProductData,
    currentPage,
    rowsPerPage,
    selectedBlobTimeIncrement: timeIncrement
  } = useSelector((state: any) => state.productData);
  const dispatch = useDispatch()
  const isLoaded = useMemo(() => {
    let identifier = `${blobPath.feed_source}/${blobPath.database_code}/${blobPath.product_code}`
    if (timeIncrement) {
      identifier += `/${timeIncrement}`
    }
    return loadedProductData && loadedProductData[identifier]
  }, [
    timeIncrement,
    loadedProductData,
    blobPath
  ])
  const fetchProducts = useCallback(() => {
    if(isLoaded || blobPath.beaDatabaseCode !== '') return
    const params = {
      ...blobPath,
      timeIncrement: timeIncrement,
      page: currentPage,
      pageSize: rowsPerPage
    };
    console.log(params, 'params')
    try {
      if(qaMode || timeIncrement !== null) {
        // @ts-ignore
        dispatch(fetchStockProductData(params))
      }
    } catch (error) {
      console.error('Error fetching stock products:', error);
    }
  }, [
    blobPath,
    dispatch,
    qaMode,
    timeIncrement,
    isLoaded
  ])

  return {
    fetchProducts
  }
};

export default useFetchCloudStorageData;