import React, {useCallback, useEffect, useMemo, useState} from 'react'

import { makeStyles } from "@mui/styles";
import TableView from './DetailView'
import { Box } from '@mui/material';
import {useSelector} from "react-redux";


const isDate = (val) => {
    let flag = false
    if (isNaN(val)) {
        flag = !isNaN(Date.parse(val))
    }
    return flag
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display:'flex',
        flex: '1',
        flexFlow: 'column',
        overflow: 'auto',
    },
}))


function DataTableView(props) {
    const classes = useStyles()
    let { blob, data, identifier } = props
    const [columns, setColumns] = useState({})
    const [rows, setRows] = useState([])

    const loadingTableData = useSelector(state => state.productData.loadingTableData)
    const loadingStockData = useSelector(state => state.productData.loadingStockData)
    const selectedDataOpt = useSelector(state => state.productData.selectedDataOpt)

    const customizeData = useCallback(() => {
    // if(blob === 'big-query'){
        const _columns = columns[identifier] || []
        const _rows = []
        if(Array.isArray(data) && data[0]){
            if(!columns[identifier]){
                Object.keys(data[0]).forEach(item => {
                    _columns.push({
                        id: item,
                        disablePadding: true,
                        // isDate: data[0][item].value ? isDate(data[0][item].value) : isDate(data[0][item]),
                        label: item.split('_').join(' ').toUpperCase(),
                        disabled: item === 'nId',
                        visible: item !== 'nId'
                    })
                })
            }
            data.forEach(item => {
                const _newItem = {}
                _columns.forEach(col => {
                    if(item[col.id] && item[col.id].value !== undefined){
                        _newItem[col.id] = item[col.id].value
                    }else{
                        _newItem[col.id] = item[col.id]
                    }
                })
                _rows.push(_newItem)
            })
            setRows([..._rows])
        }
        if(!columns[identifier]){
            setColumns({
                ...columns,
                [identifier]: _columns
            })
        }
            // }
    }, [
        rows,
        columns,
        identifier
    ])

    const loading = useMemo(() => {
        if(selectedDataOpt === 'big-query') return loadingTableData
        else if(selectedDataOpt === 'cloud-storage') return loadingStockData
    }, [
        loadingTableData,
        loadingStockData,
        selectedDataOpt
    ])


    useEffect(() => {
        customizeData()
    }, [
        data,
        identifier
    ])
    return (
        <Box id={blob === 'big-query' ? 'BigqueryDatatable' : 'CloudStorageDatatable'} className={classes.root}>
            <TableView
                rows={rows || []}
                fields={columns[identifier] || []}
                tableId={`${identifier}Inner`}
                loading={loading}
            />
        </Box>
    )
}

export default DataTableView