import React, {useEffect, useMemo, useState} from 'react'
import {compose} from "recompose"
import {connect, useDispatch, useSelector} from "react-redux"
import {withRouter} from "react-router"
import _ from "underscore"

import {makeStyles} from "@mui/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Paper,
  Popover,
  IconButton,
  TableHead,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  CircularProgress
} from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import FilterBar from './FilterBar'

import {stableSort, getComparator} from 'shared/utilities/tableUtils'
import {setDatumFilterOptions} from "store/slices/ComponentSlice"
import DataTableColumnFilterPopover from 'components/common/DataTableColumnFilterPopover'
import ColumnSettingPanel from './ColumnSetting'
import CustomFormInput from 'components/common/Base/CustomFormInput'
import {useProductContext} from "providers/product/ProductProvider";
import {setProductOpt} from "store/slices/StockProductSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%', display: 'flex', flexFlow: 'column', height: '100%'
  }, TableContainer: {
    flex: 1, position: 'relative', overflow: 'auto', // maxHeight: '300px'
    '& th': {
      textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '230px', '& span': {
        cursor: 'pointer'
      }
    }, '&::-webkit-scrollbar': {
      width: '10px', height: '11px'
    }, /* Track */
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      background: '#888'
    },

    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
  }, table: {
    minWidth: 750,
  }, highlightCell: {
    padding: '10px', maxWidth: '400px', maxHeight: '300px'
  }, NoRecord: {
    width: '100%', textAlign: 'center', padding: 12, color: 'rgba(0, 0, 0, 0.6) !important'
  }, productStaticBarButton: {
    cursor: 'pointer',
    height: 40,
    padding: '10px',
    position: 'relative',
    transition: 'all ease-in .2s',
    borderRadius: '4px',
    marginLeft: 6,
    boxShadow: '0 0 1px 1px #e5e5f1',
    display: 'flex',
    flexFlow: 'column',
    color: '#4e4b4b',
    '&.selected': {
      boxShadow: '0 0 1px 1px #646467'
    },
    '&:hover': {
      background: '#ececec'
    },
    '&.selectedViewMode': {
      background: '#3c54db', color: 'white', '&:hover': {
        background: '#5e72e4'
      },
    },
    '& svg': {
      display: 'block', margin: '0 auto', fontSize: '35px'
    }, // '&::after': {
    //     content: '""',
    //     position: 'absolute',
    //     height: '1px',
    //     borderTop: 'solid 1px #e2dcdc',
    //     bottom: '-6px',
    //     width: 'calc(100% - 6px)'
    // },
    // '&:last-child': {
    //     '&::after': {
    //         content: 'initial'
    //     }
    // }
  }
}))

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (<Box
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    sx={{
      height: '100%', flex: 1, width: '100%', overflow: 'auto', display: 'flex', flexDirection: 'column'
    }}
    {...other}
  >
    {value === index && (<>
      {children}
    </>)}
  </Box>);
}

function TableView(props) {
  let {rows, fields = [], tableId, loading} = props
  const {isNDLSource, clickBlobHandler} = useProductContext()
  const currentPage = useSelector(state => state.productData.currentPage)
  const rowsPerPage = useSelector(state => state.productData.rowsPerPage)
  const totalRecords = useSelector(state => state.productData.totalRecords)

  const [columns, setColumns] = useState([])

  useEffect(() => {
    const _columns = []
    if (Array.isArray(fields)) {
      fields.map(item => {
        _columns.push({
          id: item.id,
          disablePadding: true,
          label: item.id.split('_').join(' ').toUpperCase(),
          disabled: item.id === 'nId',
          visible: item.id !== 'nId'
        })
      })
    }
    setColumns([..._columns])
  }, [fields])

  const classes = useStyles()
  const dispatch = useDispatch()
  const selectedDataOpt = useSelector(state => state.productData.selectedDataOpt)

  const [hiddenColumns, setHiddenColumns] = React.useState([])

  const handleChangePage = (event, newPage) => {
    dispatch(setProductOpt({
      path: 'currentPage', value: newPage
    }))
  }

  const handleChangeRowsPerPage = (event) => {
    dispatch(setProductOpt({
      path: 'rowsPerPage', value: parseInt(event.target.value, 10)
    }))
    dispatch(setProductOpt({
      path: 'currentPage', value: 0
    }))
  }


  const [hAnchorEl, setHAnchorEl] = React.useState(null)
  const [fAnchorEl, setFAchorEl] = React.useState(null)
  const handleClose = () => {
    setHAnchorEl(null)
    setFAchorEl(null)
  }
  const openFilterPop = Boolean(fAnchorEl)

  const [openedColumn, setOpenedColumn] = useState(null)
  const filterPop = (openedColumn) => (event) => {
    setFAchorEl(event.currentTarget)
    setOpenedColumn(openedColumn)
  }
  const [columnSort, setColumnSort] = useState({})
  const [columnFilterValues, setColumnFilterValues] = useState({})


  const highlightCell = (event) => {
    setHAnchorEl(event.currentTarget)
  }
  const openhighlightCell = Boolean(hAnchorEl)

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, totalRecords - currentPage * rowsPerPage)

  const visibleColumns = useMemo(() => {
    if (hiddenColumns.length > 0) {
      return columns.filter(item => hiddenColumns.indexOf(item.id) < 0)
    }
    return columns
  }, [columns, hiddenColumns])


  // const columnOrders = selectedFilter ? resolvePath(filters, selectedFilter + '.orders', {}) : {}
  // for (let orderBy in columnOrders) {
  //     console.log(orderBy, columnOrders[orderBy])
  //     rows = stableSort(rows, getComparator('asc', 'record_date'))
  // }

  const handleSaveFilter = (items) => {
    handleClose()
    setOpenedColumn(null)
    setColumnFilterValues({
      ...columnFilterValues, [openedColumn]: [...items]
    })
  }

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const records = useMemo(() => {
    let _rows = [...rows];
    for (let col in columnFilterValues) {
      if (columnFilterValues[col].length > 0) {
        _rows = _rows.filter(item => columnFilterValues[col].indexOf(item[col]) > -1)
      }
    }
    for (let col in columnSort) {
      _rows = stableSort(_rows, getComparator(columnSort[col], col))
    }
    console.log(_rows, '_rows')
    // return _rows.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage)
    return _rows
  }, [rows, columnSort, getComparator, columnFilterValues, currentPage, rowsPerPage])
  useEffect(() => {
    if (openedColumn) {
      const groupedData = _.groupBy(records, function (i) {
        return i[openedColumn]
      })
      const keys = _.keys(groupedData)
      const filterDatumOptions = keys.map(i => {
        if (isNaN(i)) return i
        else return Number(i)
      })
      dispatch(setDatumFilterOptions([...filterDatumOptions]))
    }
  }, [records, openedColumn, dispatch])

  return (columns.length > 0 && (<Paper className={classes.paper}>
    {Object.keys(columnFilterValues).length > 0 && (<FilterBar
      filters={columnFilterValues}
      handleClearFilter={(fKey) => {
        const _columnFilterValues = {...columnFilterValues}
        delete _columnFilterValues[fKey]
        setColumnFilterValues({..._columnFilterValues})
      }}
    />)}
    <Box display="flex" sx={{borderBottom: 1, borderColor: 'divider', paddingTop: '9px'}}>
      <Box sx={{flex: 1, paddingLeft: '30px', display: 'flex', justifyContent: 'space-between'}}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Table"/>
          <Tab label={`Columns (${visibleColumns?.length}/${columns?.length || 0})`}/>
        </Tabs>
        {isNDLSource && (<Box sx={{display: 'flex', justifyContent: 'end'}} id="QaBlobHandler">
          <Tooltip title="Cloud Storage">
            <Box
              className={`${classes.productStaticBarButton} ${selectedDataOpt === 'cloud-storage' ? 'selected' : ''}`}
              mr={1}
              onClick={() => clickBlobHandler('cloud-storage')}
              id="cloud-storage-data-opt"
            >CS</Box>
          </Tooltip>
          <Tooltip title="Big Query">
            <Box
              className={`${classes.productStaticBarButton} ${selectedDataOpt === 'big-query' ? 'selected' : ''}`}
              onClick={() => clickBlobHandler('big-query')}
              id="big-query-data-opt"
            >BG</Box>
          </Tooltip>
        </Box>)}
      </Box>
      <Box display="flex" sx={{justifyContent: 'flex-end', paddingRight: 1}}>
        {/* <ColumnVisblePop
                            headCells={columns}
                            hiddenColumns={hiddenColumns} 
                            setHiddenColumns={setHiddenColumns}
                        /> */}
      </Box>
    </Box>
    <TabPanel value={tabValue} index={0}>
      <Box display="flex" sx={{
        width: '100%',
        flex: 1,
        overflow: 'auto',
        border: 'solid 1px rgb(0 0 0 / 10%)',
        borderLeft: 0,
        borderRight: 0,
        height: '100%'
      }}>
        {totalRecords > 0 ? (<>
          <Box className={classes.TableContainer}>
            {loading && (<Box sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center'
            }}>
              <CircularProgress sx={{margin: '100px auto', display: 'block'}} color="secondary"/>
            </Box>)}
            <TableContainer classes={{root: classes.TableContainerRoot}} id={records.length > 0 ? tableId : ''}>
              <Table
                stickyHeader
                classes={{root: classes.table}}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="center">No</TableCell>
                    {visibleColumns.map((column) => (<TableCell
                      key={column.id}
                      align={'left'}
                      style={{minWidth: 'auto'}}
                    >
                      <div className={classes.TableCellFilter}>
                        <div>
                          <span>{column.label}</span>
                          <IconButton
                            aria-label="delete"
                            className={classes.margin}
                            size="small"
                            onClick={filterPop(column.id, column.isDate ? 'date' : null)}>
                            <ExpandMoreIcon fontSize="inherit"/>
                          </IconButton>
                        </div>
                      </div>
                    </TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {records.map((row, index) => {
                    return (<TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left">{currentPage * rowsPerPage + index + 1}</TableCell>
                      {visibleColumns.map((column, c_index) => (<TableCell
                        key={c_index}
                        component="th"
                        scope="row"
                      >
                        <span title={row[column.id]}
                              onClick={highlightCell}>
                            {row[column.id] instanceof Date ? row[column.id].toLocaleDateString('en-US') : (row[column.id] && typeof row[column.id] === 'object' ? row[column.id].value : row[column.id])}
                        </span>
                      </TableCell>))}
                    </TableRow>)
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 53 * emptyRows}}> <TableCell colSpan={visibleColumns.length + 1}/>
                    </TableRow>)}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>) : (<Typography className={classes.NoRecord}>There is no data</Typography>)}
      </Box>
      <TablePagination
        rowsPerPageOptions={[500, 1000, 5000]}
        component="div"
        count={totalRecords}
        rowsPerPage={rowsPerPage}
        page={currentPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TabPanel>
    <TabPanel value={tabValue} index={1}>
      <ColumnSettingPanel
        headCells={columns}
        hiddenColumns={hiddenColumns}
        setHiddenColumns={setHiddenColumns}
      />
    </TabPanel>
    <Popover
      id={'highlightCell-popover'}
      open={openhighlightCell}
      anchorEl={hAnchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top', horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top', horizontal: 'left',
      }}
    >
      <div className={classes.highlightCell}>
        {hAnchorEl && hAnchorEl.innerText}
      </div>
    </Popover>
    <DataTableColumnFilterPopover
      open={openFilterPop}
      anchorEl={fAnchorEl}
      handleClose={handleClose}
      handleSelection={handleSaveFilter}
      direction={columnSort[openedColumn] || null}
      filterValues={columnFilterValues[openedColumn] || []}
      handleSortClick={(direction) => {
        setColumnSort({
          ...columnSort, [openedColumn]: direction
        })
      }}
    />
  </Paper>))
}

const mapStateToProps = (state) => ({
  productData: state.productData,
});

const mapDispatchToProps = (dispatch) => ({})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps),)(TableView)