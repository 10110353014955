import React, { useMemo } from 'react';
import { makeStyles } from "@mui/styles";

import {
  Grid,
} from "@mui/material";
import {useSelector} from "react-redux";
import {PieDataPanel} from "./Pie";
import DefaultPanel from "./Default";

const useStyles = makeStyles(() => ({
  root: {
    padding: '10px'
  }
}));

export const FilterPanel = () => {
  const series = useSelector(state => state.productData.series)
  const classes = useStyles()
  const chartType = useMemo(() => {
    const idx = series?.findIndex(item => item.id === 'default')
    if(idx > -1){
      return series[idx]?.chartType || 'line'
    }
    return 'line'
  }, [
    series
  ])

  return (
    <Grid container spacing={1} className={classes.root}>
      <DefaultPanel />
    </Grid>
  )
};
